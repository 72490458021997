// components/Navbar.js
import React, {useState, useCallback} from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

function Navbar({setActiveComponent, activeComponent}) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleNavClick = useCallback((component) => {
        setActiveComponent(component);
        setDrawerOpen(false);
    }, [setActiveComponent]);

    const drawerList = (
        <List>
            {['Home', 'About Us', 'Gallery', 'Events', 'Contact Us'].map((text) => {
                const componentName = text === 'About Us' ? 'About' : text === 'Contact Us' ? 'Contact' : text;
                return (
                    <ListItem button key={text} onClick={() => handleNavClick(componentName)}>
                        <ListItemText primary={text} />
                    </ListItem>
                );
            })}
        </List>
    );


    return (
        <>
            <AppBar position="static" style={{backgroundColor: '#ffffff'}}>
                <Toolbar>
                    {isMobile && (
                        <IconButton
                            edge="start"
                            style={{color: '#000000'}}
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                        <img src={require('../../assets/hanuman.png')} alt="Hanuman Logo"
                             style={{height: '60px', marginRight: '10px'}}/>
                        <img src={require('../../assets/jaihanuman.png')} alt="Jai Hanuman Logo"
                             style={{height: '50px'}}/>
                    </div>
                    {!isMobile && (
                        <div className="desktop-menu">
                            {['Home', 'About Us', 'Gallery', 'Events', 'Contact Us'].map((text) => (
                                <Button key={text} style={{
                                    textTransform: 'none', fontFamily: 'Inter, Helvetica', fontSize: '16px',
                                    fontWeight: 700, marginRight: '16px', width: '110px', height: '40px',
                                    color: (activeComponent === 'About' && text === 'About Us')
                                    || (activeComponent === 'Contact' && text === 'Contact Us')
                                    || activeComponent === text ? '#ffffff' : '#000000',
                                    backgroundColor: (activeComponent === 'About' && text === 'About Us')
                                    || (activeComponent === 'Contact' && text === 'Contact Us')
                                    || activeComponent === text ? '#ff511a' : 'transparent'
                                }}
                                        onClick={() => handleNavClick(text === 'About Us' ? 'About' : text === 'Contact Us' ? 'Contact' : text)}>

                                    {text}
                                </Button>
                            ))}
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerList}
            </Drawer>
        </>
    );
}

export default Navbar;