// components/Footer.js
import React from 'react';
import { Typography } from '@mui/material';

function Footer() {
    return (
        <footer className="footer" style={{ backgroundColor: '#ff511a', color: 'white', padding: '1rem', textAlign: 'center', bottom: '0', marginTop: 'auto' }}>
            <Typography variant="body1">&copy; 2024 Sri Hanuman Temple, Ariyalur. All rights reserved.</Typography>
        </footer>
    );
}

export default Footer;