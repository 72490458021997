import React from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

function Events() {
    const [open, setOpen] = useState(false);
    const [currentPoster, setCurrentPoster] = useState(null);

    const handlePosterClick = (poster) => {
        setCurrentPoster(poster);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentPoster(null);
    };
    const dailyEvents = [
        { day: 'Everyday', time: '6:00 PM', pooja: 'Daily Pooja', notes: '', },
        { day: 'Thursday', time: '5:30 PM to 6:30 PM', pooja: 'Vishnu Sahasranamam', notes: 'Curd rice is distributed as special prasadam for devotees'},
        { day: 'Saturday', time: '5:30 PM to 6:45 PM', pooja: 'Hanuman Pooja', notes: 'Special Abhishekam and Pooja rituals'}
    ];

    const specialEvents = [
        { day: '1st November 2024', time: '-', pooja: 'Maha Abhishekam, Pushparadhanai, Shodaubacharam', notes: 'Deepavali 2024', poster: '/gallery/deepavali2024.jpg' }
    ];

    return (
        <Container id="events" className="events" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Typography variant="h4" gutterBottom>Daily Events</Typography>
            <TableContainer component={Paper} style={{ marginBottom: '2rem' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1" paragraph>Day</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Time</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Pooja</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Notes</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dailyEvents.map((event, index) => (
                            <TableRow key={index}>
                                <TableCell><Typography variant="body1" paragraph>{event.day}</Typography></TableCell>
                                <TableCell><Typography variant="body1" paragraph>{event.time}</Typography></TableCell>
                                <TableCell><Typography variant="body1" paragraph>{event.pooja}</Typography></TableCell>
                                <TableCell><Typography variant="body1" paragraph>{event.notes}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h4" gutterBottom>Special Events</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1" paragraph>Day</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Time</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Pooja</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Notes</Typography></TableCell>
                            <TableCell><Typography variant="body1" paragraph>Image</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {specialEvents.map((event, index) => (
                            <TableRow key={index}>
                                <TableCell><Typography variant="body1" paragraph>{event.day}</Typography></TableCell>
                                <TableCell><Typography variant="body1" paragraph>{event.time}</Typography></TableCell>
                                <TableCell><Typography variant="body1" paragraph>{event.pooja}</Typography></TableCell>
                                <TableCell><Typography variant="body1" paragraph>{event.notes}</Typography></TableCell>
                                <TableCell>
                                    <img src={event.poster} alt={`Poster ${index + 1}`} style={{ width: '50px', height: 'auto', cursor: 'pointer' }} onClick={() => handlePosterClick(event.poster)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                        maxWidth: '90%',
                        maxHeight: '90%',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={currentPoster} alt="Current Poster" style={{ width: '100%', height: 'auto' }} />
                </Box>
            </Modal>
        </Container>
    );
}

export default Events;
