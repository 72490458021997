// components/About.js
import React from 'react';
import {Button, Container, Grid, Typography} from '@mui/material';

function About() {
    return (
        <Container id="home" className="home" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={7}>
                    <Typography variant="h3" gutterBottom>About the temple</Typography>
                    <Typography variant="body1" paragraph>
                        The quaint little town of Ariyalur, Tamil Nadu, India, is home to the magnificent Shri
                        Anjaneya Temple which is dedicated to the worship of Lord Hanuman. The temple has a rich
                        history that spans across a century and over the years, the revered deity has drawn
                        devotees from diverse corners of the globe.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The Shri Anjaneya Temple was consecrated on August 1st, 1918, by the esteemed Shri
                        Periaswamy Iyer, affectionately known as Ariyalur Appa. Hanuman Jayanthi, the temple's
                        annual festival commemorating the auspicious birth of Lord was first inaugurated on
                        December 20th, 1930. It is noteworthy that the celebration of Hanuman Jayanthi began
                        following the birth of Shri Guruswamy Iyer, the son of Shri Periaswamy Iyer. <br/>
                    </Typography>

                    <Typography variant="body1" paragraph>
                        The temple has since celebrated 93 consecutive Hanuman Jayanthis and is renowned for its
                        grand festivities, rituals and poojas. This annual event is celebrated over several days
                        and witnesses a sea of devotees who fervently worship and pay homage to the divine Lord.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Delving into the temple's historical roots reveals an intriguing tale from the early 19th
                        century. Shri Periaswamy Iyer, guided by a celestial vision, was instructed by Lord
                        Hanuman to excavate a precise location, now known as the Nandavanam or temple garden.
                        Responding to this divine directive, Shri Periaswamy Iyer unearthed a sacred deity, now
                        venerated within the temple precincts. Lord Hanuman, revered as a Swayambhu, symbolizing a
                        self-born manifestation, adds a unique spiritual dimension to the temple.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        With roots deeply embedded in history, Ariyalur Appa's vision materialized into the
                        magnificent temple structure that has gracefully endured the test of time for more than a
                        century. The Shri Anjaneya Temple stands as a testament to faith, heritage, and the
                        enduring connection between the divine and the devoted.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img src={require("../../assets/about.jpg")} alt="Temple"
                         style={{width: '100%', height: 'auto'}}/>
                </Grid>
            </Grid>
        </Container>
    );
}

export default About;