import React, { useState } from 'react';
import { Container, Grid, Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Gallery() {
    const images = [
        '/gallery/img1.jpeg',
        '/gallery/img2.jpeg',
        '/gallery/img3.jpeg',
        '/gallery/img4.jpeg',
        '/gallery/img9.png',
        '/gallery/img6.jpeg',
        '/gallery/img7.jpeg',
        '/gallery/img8.jpeg',
        '/gallery/img5.jpeg',
        '/gallery/img10.jpg',
        '/gallery/img11.jpg'
    ];

    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const handleOpen = (image) => {
        setCurrentImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImage(null);
    };

    return (
        <Container id="gallery" className="gallery" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Grid container spacing={4}>
                {images.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpen(image)}>
                        <img src={image} alt={`Gallery Image ${index + 1}`} style={{ width: '100%', height: 'auto', cursor: 'pointer' }} />
                    </Grid>
                ))}
            </Grid>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                        maxWidth: '90%',
                        maxHeight: '90%',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={currentImage} alt="Current" style={{ width: '100%', height: 'auto' }} />
                </Box>
            </Modal>
        </Container>
    );
}

export default Gallery;

