// components/Home.js
import React, {useState} from 'react';
import {Container, Typography, Button, Grid, Modal, Box, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Home({setActiveComponent}) {
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const handleImageClick = (image) => {
        setCurrentImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImage(null);
    };

    return (
        <Container id="home" className="home" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={7}>
                    <Typography variant="h3" gutterBottom>Shri Anjaneya Temple</Typography>
                    <Typography variant="body1" paragraph>
                        <p className="shri-anjaneya-temple">
                            The quaint little town of Ariyalur, Tamil Nadu, India, is home to the magnificent Shri
                            Anjaneya Temple which is dedicated to the worship of Lord Hanuman. The temple has a rich
                            history that spans across a century and over the years, the revered deity has drawn
                            devotees from diverse corners of the globe.
                            <br/><br/>
                            The Shri Anjaneya Temple was consecrated on August 1st, 1918, by the esteemed Shri
                            Periaswamy Iyer, affectionately known as Ariyalur Appa. Hanuman Jayanthi, the temple's
                            annual festival commemorating the auspicious birth of Lord was first inaugurated on
                            December 20th, 1930.
                        </p>
                    </Typography>
                    <Button
                        variant="contained"
                        style={{
                            marginTop: '1rem',
                            backgroundColor: '#ff511a',
                            color: '#ffffff',
                            fontFamily: 'Inter, Helvetica',
                            fontSize: '16px',
                            fontWeight: 700,
                            marginRight: '16px',
                            textTransform: 'none',
                            width: '160px',
                            height: '40px'
                        }}
                        onClick={() => setActiveComponent('About')}
                    >
                        Tell me more...
                    </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img
                        src={require('../../assets/hanuman-art.png')}
                        alt="Temple"
                        style={{width: '100%', height: 'auto', cursor: 'pointer'}}
                        onClick={() => handleImageClick(require('../../assets/hanuman-art.png'))}
                    />
                </Grid>
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                        maxWidth: '90%',
                        maxHeight: '90%',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={currentImage} alt="Enlarged Temple" style={{ width: '100%', height: 'auto' }} />
                </Box>
            </Modal>
        </Container>
    );
}

export default Home;
