// components/Contact.js
import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

function Contact() {
    return (
        <Container id="contact" className="contact" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>Contact Us</Typography>
                    <Typography variant="body1" paragraph>
                        For inquiries or more information, please contact us at the following:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Email:</strong> ariyalurhanumantemple@gmail.com
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Phone:</strong> (+91) 9600966771
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Address:</strong> Arulmigu Shri Anjaneyar Temple, Perumal Koil Street, MIN Nagar, Ariyalur, Tamil Nadu 621704, India
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <iframe title="maps" width="100%" height="450" loading="lazy" style={{ border: '0', maxWidth: '100%' }}
                            allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJp2H2cHvfqjsRtnJlyRgpmaA&key=AIzaSyBEK_lSk8di0C8ysXv24mmhBx3w_YBU0Yc"></iframe>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Contact;