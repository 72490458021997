import React, {useState, useCallback} from 'react';
import {CssBaseline} from '@mui/material';
import './styles/index.css';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import About from './components/about/About';
import Gallery from './components/gallery/Gallery';
import Events from './components/events/Events';
import Contact from './components/contact/ContactUs';
import Footer from './components/footer/Footer';
import './components/footer/Footer.css'


function App() {
    const [activeComponent, setActiveComponent] = useState('Home');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'Home':
                return <Home setActiveComponent={setActiveComponent} />;
            case 'About':
                return <About />;
            case 'Gallery':
                return <Gallery />;
            case 'Events':
                return <Events />;
            case 'Contact':
                return <Contact />;
            default:
                return <Home />;
        }
    };

    return (
        <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <Navbar setActiveComponent={(comp) => setActiveComponent(comp)}  activeComponent={activeComponent} />
            <div style={{ flex: 1 }}>{renderComponent()}</div>
            <Footer />
        </div>
    );
}
export default App;